import React, { useEffect } from 'react'

const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy Policy"
  }, [])
  return (
    <div style={{width:'70%', margin: 'auto'}}>
        <h1 style={{textAlign:'center', paddingBottom: '1%'}}>Privacy Policy</h1>
        <p style={{textAlign:'center', fontSize:'100%'}}>The app we have made so far (MemeCon, NeoAI and Nutrition Watch) are completely safe for children. We do not collect any personally identifiable information from children under the age of 13. We do not knowingly contact or collect personal information from children under the age of 13. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will delete such information from our records. If you believe that we have collected personal information from a child under the age of 13, please contact us at navyanshkesarwani@gmail.com.</p>
    </div>
  )
}

export default Privacy
